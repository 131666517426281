var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"profile"}},[_c('Banner',{attrs:{"bgUserProfile":_vm.bgUserProfile,"id":this.$route.params.id,"editMode":false}}),(this.connectedUserID == this.$route.params.id)?_c('v-row',{staticClass:"d-flex flex-row-reverse mt-2"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/profile/edit"}},[_c('v-btn',{staticClass:"mt-2",class:_vm.$style.btnEdit},[_vm._v("Edit")])],1)],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column justify-space-around px-md-16 px-4 my-12 mx-4"},[_c('v-row',{staticClass:"\n        d-flex\n        flex-md-row flex-column\n        align-center align-md-stretch\n        justify-space-between\n      "},[_c('v-col',{staticClass:"ma-4 px-8",class:_vm.$style.card,attrs:{"cols":"11","md":"3"}},[_c('h3',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$style.secondaryTitle
              : _vm.$style.secondaryTitleMobile},[_vm._v(" About me ")]),_c('p',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$style.description
              : _vm.$style.descriptionMobile},[_vm._v(" "+_vm._s(this.aboutMe)+" ")]),_c('p',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$style.description
              : _vm.$style.descriptionMobile},[_vm._v(" Birthday: "+_vm._s(this.birthdayDate ? this.birthdayDate : "Unknown")+" ")]),_c('p',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$style.description
              : _vm.$style.descriptionMobile},[_vm._v(" Country: "+_vm._s(this.country ? this.country : "Unknown")+" ")])]),_c('v-col',{staticClass:"ma-4 px-8",class:[_vm.$style.card],attrs:{"cols":"11","md":"8"}},[_c('h3',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$style.secondaryTitle
              : _vm.$style.secondaryTitleMobile},[_vm._v(" Games ")]),_c('v-slide-group',{staticClass:"my-4",attrs:{"multiple":"","show-arrows":"always","dark":""}},_vm._l((_vm.userGames),function(game,index){return _c('v-slide-item',{key:index},[_c('v-card',{staticClass:"ma-auto pa-4",attrs:{"height":"200","width":"150","color":"transparent","elevation":"0"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"rounded-lg",attrs:{"src":game.path}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(game.description))])])],1)],1)}),1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"ma-4 px-8",class:_vm.$style.card},[_c('h3',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$style.secondaryTitle
              : _vm.$style.secondaryTitleMobile},[_vm._v(" Languages ")]),_vm._l((_vm.userLanguages),function(lang){return _c('p',{key:lang,class:_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$style.description
              : _vm.$style.descriptionMobile},[_vm._v(" "+_vm._s(lang)+" ")])})],2),_c('v-col',{staticClass:"ma-4 px-8",class:_vm.$style.card},[_c('h3',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$style.secondaryTitle
              : _vm.$style.secondaryTitleMobile},[_vm._v(" Social media ")]),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-img',{attrs:{"src":require('../assets/profile/twitter.png'),"max-height":30,"max-width":30,"contain":""}}),_c('p',{staticClass:"ml-2",class:_vm.$vuetify.breakpoint.mdAndUp
                  ? _vm.$style.description
                  : _vm.$style.descriptionMobile},[_vm._v(" "+_vm._s(this.twitterId ? this.twitterId : "Unknown")+" ")])],1),_c('v-col',{staticClass:"d-flex"},[_c('v-img',{attrs:{"src":require('../assets/profile/instagram.png'),"max-height":30,"max-width":30,"contain":""}}),_c('p',{staticClass:"ml-2",class:_vm.$vuetify.breakpoint.mdAndUp
                  ? _vm.$style.description
                  : _vm.$style.descriptionMobile},[_vm._v(" "+_vm._s(this.instagramId ? this.instagramId : "Unknown")+" ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-img',{attrs:{"src":require('../assets/profile/discord.png'),"max-height":30,"max-width":30,"contain":""}}),_c('p',{staticClass:"ml-2",class:_vm.$vuetify.breakpoint.mdAndUp
                  ? _vm.$style.description
                  : _vm.$style.descriptionMobile},[_vm._v(" "+_vm._s(this.discordId ? this.discordId : "Unknown")+" ")])],1),_c('v-col',{staticClass:"d-flex"},[_c('v-img',{attrs:{"src":require('../assets/profile/twitch.png'),"max-height":30,"max-width":30,"contain":""}}),_c('p',{staticClass:"ml-2",class:_vm.$vuetify.breakpoint.mdAndUp
                  ? _vm.$style.description
                  : _vm.$style.descriptionMobile},[_vm._v(" "+_vm._s(this.twitchId ? this.twitchId : "Unknown")+" ")])],1)],1)],1),_c('v-col',{staticClass:"ma-4 px-8",class:_vm.$style.card},[_c('h3',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$style.secondaryTitle
              : _vm.$style.secondaryTitleMobile},[_vm._v(" Platforms ")]),_c('v-row',_vm._l((_vm.userPlatforms),function(platform,index){return _c('v-col',{key:index,staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"rounded-lg",attrs:{"src":platform.path,"max-width":"100","contain":""},on:{"click":function($event){return _vm.copyText(platform.description)}}},on))]}}],null,true)},[_c('span',{attrs:{"id":platform.description}},[_vm._v(_vm._s(platform.description))])]),_c('v-snackbar',{attrs:{"timeout":"1000","content-class":"text-center","dark":""},model:{value:(_vm.copySnackbar),callback:function ($$v) {_vm.copySnackbar=$$v},expression:"copySnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }